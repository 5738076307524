import React, {
  useEffect,
  useRef,
  useMemo,
  createContext,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { useUID } from 'react-uid'
import { Popover } from '@headlessui/react'
import * as riPropTypes from '@/util/riPropTypes'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import createClassName from '@/util/createClassName'
import { PanelBodyContext } from '@/components/organisms/Panel'

export const FormGroupContext = createContext({
  inputProps: {},
  labelProps: {},
  error: false,
})

export const FormGroup = ({
  controlId,
  labelText,
  errors,
  errorText,
  tooltip,
  labelProps = {},
  forwardedRef,
  children,
  containInput = true,
  labelComponent: LabelComponent = 'label',
}) => {
  const uid = useUID()

  const formGroupRef = useRef()

  // check that FormGroup is still mounted before doing anything
  const mountedRef = useRef(true)

  useEffect(() => {
    mountedRef.current = true
    return () => (mountedRef.current = false)
  }, [])

  const { panelType } = useContext(PanelBodyContext) || {}

  // we need to separate the actual blur/focus action since we can tab
  // between different fields/controls inside the FormGroup
  //
  // const [isFocused, setIsFocused] = useState(false)
  // const [isManagingFocus, setIsManagingFocus] = useState(false)

  // const manageTimer = useRef()
  // const focusTimer = useRef()

  // useEffect(() => {
  //   focusTimer.current = setTimeout(() => {
  //     if (isManagingFocus !== isFocused && mountedRef.current) {
  //       setIsFocused(isManagingFocus)
  //       if (isManagingFocus && onFocus) {
  //         onFocus()
  //       }
  //       if (!isManagingFocus && onBlur) {
  //         onBlur()
  //       }
  //     }
  //   }, 10)

  //   return () => clearTimeout(focusTimer)
  // }, [isManagingFocus, isFocused, onFocus, onBlur])

  // const focus = useCallback(() => {
  //   clearTimeout(manageTimer.current)
  //   if (!isManagingFocus && mountedRef.current) {
  //     setIsManagingFocus(true)
  //   }
  // }, [isManagingFocus])

  // const blur = useCallback(() => {
  //   if (mountedRef.current) {
  //     manageTimer.current = setTimeout(() => {
  //       if (isManagingFocus && mountedRef.current) {
  //         setIsManagingFocus(false)
  //       }
  //     }, 0)
  //   }
  // }, [isManagingFocus])

  const contextValue = useMemo(() => {
    const id = `${controlId}_${uid}` || uid
    const inputProps = {
      id,
    }

    if (tooltip) {
      inputProps['aria-describedby'] = `helphint_${id}`
    }

    return {
      // isFocused,
      controlId,
      inputProps,
      labelProps: {
        ...labelProps,
        htmlFor: inputProps.id,
        id: `label_${inputProps.id}`,
      },
      error: !!errors || !!errorText,
    }
  }, [controlId, labelProps, tooltip, uid, errors, errorText])

  return (
    <FormGroupContext.Provider value={contextValue}>
      <div>
        <div className="flex align-middle">
          <LabelComponent
            className="flex-1 block text-sm font-medium text-gray-700"
            {...contextValue.labelProps}
          >
            {labelText}
          </LabelComponent>
          {tooltip && (
            <Popover
              className={createClassName([
                'relative flex-shrink-0',
                panelType === 'full' && 'sm:hidden',
              ])}
            >
              <Popover.Button className="flex-shrink-0 text-link" tabIndex="-1">
                <QuestionMarkCircleIcon className="h-4 w-4 cursor-help inline-block" />
              </Popover.Button>
              <Popover.Panel className="absolute z-20 right-0 bg-white overflow-hidden shadow-md px-4 py-5 text-sm w-64">
                {tooltip}
              </Popover.Panel>
            </Popover>
          )}
        </div>
        <div
          className={createClassName([
            'mt-1 grid grid-cols-1 gap-x-8',
            panelType === 'full' && 'sm:grid-cols-6',
          ])}
        >
          <div
            className={createClassName([
              panelType === 'full' && 'sm:col-span-4',
            ])}
            ref={forwardedRef || formGroupRef}
          >
            <div
              className={createClassName([
                'relative flex',
                containInput && 'bg-white rounded-md shadow-sm',
              ])}
            >
              {children}
            </div>
            {errorText && (
              <div className="mt-2 text-sm text-red-600">{errorText}</div>
            )}
            {errors && (
              <div className="mt-2 text-sm text-red-600">
                {errors.map((e, i) => (
                  <div key={i}>{e?.message || e}</div>
                ))}
              </div>
            )}
          </div>
          {panelType === 'full' && (
            <div className="sm:col-span-2 self-center">
              {tooltip && (
                <div
                  className="sr-only sm:not-sr-only text-xs text-gray-500"
                  id={contextValue.inputProps['aria-describedby']}
                >
                  {tooltip}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </FormGroupContext.Provider>
  )
}

FormGroup.propTypes = {
  componentState: riPropTypes.componentState,
  controlId: PropTypes.string,
  labelText: PropTypes.string,
  tooltip: PropTypes.node,
}

export default React.forwardRef((props, ref) => (
  <FormGroup {...props} forwardedRef={ref} />
))
