import React, {
  useLayoutEffect,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  createContext,
  useContext,
} from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import PropTypes from 'prop-types'
import FormActions from '@/components/molecules/forms/FormActions'
import CombinedErrorMessages from '@/components/molecules/CombinedErrorMessages'
import { XMarkIcon } from '@heroicons/react/24/outline'
import createClassName from '@/util/createClassName'
import { Alert, Loader } from '@ralston-instruments/app.fieldlab.components.web'

export const PanelBodyContext = createContext()

function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow

    const originalStyle = window.getComputedStyle(document.body).overflow

    // Prevent scrolling on mount

    document.body.style.overflow = 'hidden'

    // Re-enable scrolling when component unmounts

    return () => (document.body.style.overflow = originalStyle)
  }, []) // Empty array ensures effect is only run on mount and unmount
}

if (process.env.NODE_ENV === 'test') {
  const x = document.createElement('div')
  x.id = 'root'
  document.body.appendChild(x)
}

const InnerContainer = ({ children, onSubmit, panelType }) => {
  const onSubmitCallback = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      onSubmit()
    },
    [onSubmit]
  )

  const className = createClassName([
    'form-container h-full bg-white shadow-xl',
    panelType === 'full' ? 'flex flex-col' : 'overflow-y-auto',
  ])

  return onSubmit ? (
    <form autoComplete="off" onSubmit={onSubmitCallback} className={className}>
      {children}
    </form>
  ) : (
    <div className={className}>{children}</div>
  )
}

export const PanelBodyRefContext = React.createContext()

export const PanelBody = ({ children }) => {
  const ref = useRef()
  const { title, description, close, hideCloseX, panelType } =
    useContext(PanelBodyContext)
  return (
    <PanelBodyRefContext.Provider value={ref}>
      <div
        className={createClassName([
          'min-h-0 flex flex-col pb-6',
          panelType === 'full' ? 'flex-1 overflow-y-auto' : '',
        ])}
      >
        <div className="px-4 sm:px-6 bg-gray-50 py-6">
          <div className="flex items-start justify-between">
            <div className="space-y-1">
              <h2 className="text-lg font-medium text-gray-900">{title}</h2>
              {description && (
                <p className="text-sm text-gray-500">{description}</p>
              )}
            </div>
            <div className="ml-3 h-7 flex items-center">
              {!hideCloseX && (
                <button
                  type="button"
                  className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500"
                  onClick={() => close()}
                  aria-label="Close panel"
                >
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="mt-6 relative flex-1 px-4 sm:px-6">{children}</div>
      </div>
    </PanelBodyRefContext.Provider>
  )
}

export const PanelFoot = ({
  showErrors,
  errors,
  formData,
  errorMessage,
  cancelText,
  cancelAction,
  saveAction = 'submit',
  saveText,
  saveDisabled,
  errorHeader,
  header,
  saveActionComponentState,
  excludeErrorKeys,
  size,
  cancelRef,
  saveRef,
}) => (
  <div className="flex-shrink-0">
    {showErrors && (
      <Alert title={errorHeader} fullWidth>
        <CombinedErrorMessages
          errorMessage={errorMessage}
          errors={errors}
          formData={formData}
          excludeErrorKeys={excludeErrorKeys}
        />
      </Alert>
    )}
    <FormActions
      size={size}
      text={header}
      cancelText={cancelText}
      cancelAction={cancelAction}
      saveText={saveText}
      saveAction={saveAction}
      saveDisabled={saveDisabled}
      saveActionComponentState={saveActionComponentState}
      cancelRef={cancelRef}
      saveRef={saveRef}
    />
  </div>
)

const Panel = ({
  title,
  description,
  panelType,
  // panelLayer,
  onSubmit,
  close,
  loadingStatus,
  loadingTitle,
  isOpen,
  isUnder,
  children,
  panelListIndex = 0,
  initialFocusRef,
  shouldFocus = false,
  hideCloseX = false,
}) => {
  useLockBodyScroll()

  const hasFocusedOnce = useRef(false)
  const timerRef = useRef()

  useEffect(() => {
    if (
      shouldFocus &&
      !hasFocusedOnce.current &&
      initialFocusRef &&
      !loadingStatus
    ) {
      timerRef.current = window.setTimeout(() => {
        if (initialFocusRef.current) {
          initialFocusRef.current.focus()
          if (initialFocusRef.current.nodeName.toLowerCase() !== 'button') {
            initialFocusRef.current.click()
          }
        }
      }, 200)
      hasFocusedOnce.current = true
    }

    return () => clearTimeout(timerRef.current)
  }, [shouldFocus, initialFocusRef, hasFocusedOnce, loadingStatus])

  const contextValue = useMemo(
    () => ({
      title,
      description,
      close,
      hideCloseX,
      panelType,
    }),
    [title, description, close, hideCloseX, panelType]
  )

  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={close}
      className="fixed inset-0 overflow-hidden bg-gray-500 bg-opacity-90"
      style={{
        zIndex: 104 + panelListIndex,
      }}
    >
      <div
        className={createClassName([
          'fixed inset-y-0 right-0 pl-8 flex sm:pl-16 max-w-full',
          isUnder ? '-translate-x-14' : '',
        ])}
      >
        <DialogContent
          className={createClassName([
            'w-screen',
            panelType === 'full' ? 'max-w-6xl' : 'max-w-md',
          ])}
          aria-label={title}
        >
          <PanelBodyContext.Provider value={contextValue}>
            <InnerContainer onSubmit={onSubmit} panelType={panelType}>
              {loadingStatus ? (
                <div className="min-h-full flex flex-col">
                  <Loader
                    title={loadingTitle}
                    done={loadingStatus === 'done'}
                  />
                </div>
              ) : (
                <>{children}</>
              )}
            </InnerContainer>
          </PanelBodyContext.Provider>
        </DialogContent>
      </div>
    </DialogOverlay>
  )
}

Panel.defaultProps = {
  panelType: 'full',
  close: () => null,
  isOpen: false,
  isUnder: false,
}

Panel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  panelType: PropTypes.oneOf(['full', 'modal', 'mini']),
  panelLayer: PropTypes.number,
  onSubmit: PropTypes.func,
  close: PropTypes.func,
  loadingStatus: PropTypes.oneOf(['loading', 'done']),
  loadingTitle: PropTypes.string,
  isOpen: PropTypes.bool,
  isUnder: PropTypes.bool,
  hideEnterAnimation: PropTypes.bool,
  hideLeaveAnimation: PropTypes.bool,
}

export default Panel
