import { useReducer, useCallback, useEffect } from 'react'

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        [action.payload.controlId]: action.payload.value,
      }

    case 'SET':
      return {
        ...state,
        ...action.payload.formData,
      }

    case 'RESET':
      return action.payload.initialFormData
  }
}

export default function useFormData(initialFormData = {}) {
  const [formData, dispatch] = useReducer(reducer, initialFormData)

  const updateInput = useCallback(
    (controlId, value) => {
      dispatch({
        type: 'UPDATE',
        payload: {
          controlId,
          value,
        },
      })
    },
    [dispatch]
  )

  const setFormData = useCallback(
    (formData) => {
      dispatch({
        type: 'SET',
        payload: {
          formData,
        },
      })
    },
    [dispatch]
  )

  const resetForm = useCallback(() => {
    dispatch({ type: 'RESET', payload: { initialFormData } })
  }, [initialFormData])

  useEffect(() => {
    resetForm()
  }, [initialFormData, resetForm])

  return {
    formData,
    updateInput,
    resetForm,
    setFormData,
  }
}
