import React from 'react'

export function filterErrorMessageKeys(errors, { formData, excludeErrorKeys }) {
  const formDataKeys = Object.keys(formData)
  return Object.keys(errors).filter(
    (key) =>
      formDataKeys.indexOf(key) === -1 && excludeErrorKeys.indexOf(key) === -1
  )
}

export function combineErrorMessages(
  errors,
  filteredKeys = errors ? Object.keys(errors) : null
) {
  return errors && filteredKeys
    ? filteredKeys.reduce((prev, cur) => [...prev, ...errors[cur]], [])
    : []
}

const CombinedErrorMessages = ({
  errorMessage,
  errors = {},
  formData = {},
  excludeErrorKeys = [],
}) => {
  const filteredKeys = filterErrorMessageKeys(errors, {
    formData,
    excludeErrorKeys,
  })
  return (
    <>
      <div>{errorMessage}</div>
      {combineErrorMessages(errors, filteredKeys).map((error, i) => (
        <div key={i}>{error?.message || error}</div>
      ))}
    </>
  )
}

export default CombinedErrorMessages
