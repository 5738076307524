import PropTypes from 'prop-types'

export const componentState = PropTypes.oneOf([
  'default',
  'primary',
  'success',
  'warning',
  'info',
  'danger',
  'link',
  'error',
])

export const size = PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])

export const singleOptionPropType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

export const manufacturer = PropTypes.object

export const product = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
})

export const iconSize = PropTypes.oneOf([
  'xs',
  'sm',
  'md',
  'lg',
  '1x',
  '2x',
  '3x',
  '4x',
  '5x',
  '6x',
  '7x',
  '8x',
  '9x',
  '10x',
])

export const buttonProps = PropTypes.shape({
  className: PropTypes.string,
  componentState: componentState,
  size: size,
  block: PropTypes.bool,
  attributes: PropTypes.object,
  href: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.oneOf(['link', 'externallink', 'button', 'submit']),
  text: PropTypes.node,
  icon: PropTypes.string,
  iconOnly: PropTypes.bool,
})
export const options = PropTypes.arrayOf(
  PropTypes.oneOfType([
    singleOptionPropType,
    PropTypes.shape({
      optgroup: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(singleOptionPropType).isRequired,
    }),
  ])
)
